import { ref, reactive, isRef, isReactive, toRaw } from 'vue';
const isComputed = (value) => value && typeof value === 'object' && value._isComputed;

function getType(value) {
    if (Array.isArray(value)) return 'array';
    if (isRef(value)) return 'ref';
    if (isReactive(value)) return 'reactive';
    if (isComputed(value)) return 'computed';
    return typeof value;
}

function getLength(value) {
    if (value === null || value === undefined) {
        // Return null or handle the case where value is null or undefined
        return null;
    }
    
    if (Array.isArray(value) || typeof value === 'object') {
        return Object.keys(value).length;
    }
    return null;
}

const seenObjects = new WeakSet();

function getMaxDepth(value, currentDepth = 0, maxDepth = 10) {
    if (currentDepth > maxDepth) {
        // Maximale Tiefe erreicht, Abbruch der Rekursion
        return currentDepth;
    }

    if (typeof value !== 'object' || value === null || seenObjects.has(value)) {
        // Kein weiteres Objekt, bereits gesehenes Objekt oder null-Wert
        return currentDepth;
    }

    seenObjects.add(value);
    let localMaxDepth = currentDepth;
    for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
            const depth = getMaxDepth(value[key], currentDepth + 1, maxDepth);
            localMaxDepth = Math.max(localMaxDepth, depth);
        }
    }

    seenObjects.delete(value); // entferne das Objekt nach dem Durchlauf
    return localMaxDepth;
}

function getDataSizeInBytes(value) {
    const cache = new Set();

    const stringified = JSON.stringify(value, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (cache.has(value)) {
                // Kreisförmige Referenz gefunden, ersetze sie durch eine benutzerdefinierte Notation
                return '[Circular]';
            }
            cache.add(value);
        }
        return value;
    });

    cache.clear(); // Den Cache leeren, um Speicher freizugeben

    return new Blob([stringified]).size;
}


const advancedTypeOf = (value) => {
    const type = getType(value);
    const rawValue = toRaw(value);

    return {
        type,
        length: getLength(rawValue),
        depth: getMaxDepth(rawValue),
        size: getDataSizeInBytes(rawValue),
        value,
    };
};

export default (input) => advancedTypeOf(input);

