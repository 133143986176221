import slugify from 'slugify'

const options = {
    lower: true, 
    strict: true,
}

const charMap = {
  'ä': 'ae',
  'ö': 'oe',
  'ü': 'ue',
  'ß': 'ss',
}

export default (str) => slugify(str.toLowerCase().replace(/[äöüß]/g, m => charMap[m]), options)